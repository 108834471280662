.docContainer {
  max-width: 900px;
  margin: auto;
  padding: 84px 16px 24px;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;

}
p, li {
  margin: 16px 0;
}
th, td {
  padding: 8px;
}

th {
  background-color: rgba(0,0,0,0.1);
}

td {
  vertical-align: top;
}

table {
  margin: 16px 0;
}

a {
  color: blue;
  text-decoration: underline;
} 