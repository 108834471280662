.homeContainer {
  display: flex;
  flex-direction: column;

}

.carouselWithHeader {
  background: url('background.png') no-repeat top center;
  background-size: cover;
  padding: 72px 0 48px;
}

.loginContainer {
  padding: 32px 16px;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  background-color: white;
}

.buttonContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginTitle {
  font-weight: bold;
  margin-bottom: 8px;
}

.changeNumber {
  font-weight: bold;
  margin-bottom: 8px;
  color: #2488F3;
  font-size: 12px;
  cursor: pointer;

}

.loginDscription {
  font-size: 12px;
  color: #474747;
  margin-bottom: 8px;
}

.phoneInputContainer {
  background-color: #EEE;
  display: flex;
  padding: 0 16px;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 8px;

}

.otpContainer {
  justify-content: space-between;
  margin: 8px 0;
  gap: 16px;
  user-select: none;
}

.code {
  color: #4aa9ff;
  font-size: 16px;
  padding: 16px 0;
  font-weight: bold;
}

.otpInput {
  outline: none;
  border: none;
  background-color: #EEE;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  user-select: none;
  box-sizing: border-box;
  flex: 1;

}

.phoneInput {
  border: none;
  padding: 16px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  outline: none;
}

.phoneInput:focus+.code {
  color: black;
}

.loginHighlight {
  color: #009A66;
  font-size: 12px;
  margin-top: 8px;

}

.resendCode,
.resendCodeHighlight {
  font-size: 12px;
  text-align: end;
}

.resendCodeHighlight {
  color: #2488F3;
  font-weight: bold;
  cursor: pointer;
}

.termsAndConditions {
  font-size: 12px;
  color: #828282;
  text-align: center;
  margin-top: 8px;

}

.tnCButton {
  color: black;
  font-weight: bold;

}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 12px;
  flex-wrap: wrap;
}
.errorContainer a {
  text-decoration: none;
}

.errorContainerOtp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.errorContainer a {
  color: #2488F3;
  font-weight: bold;
  margin: 0 2px;
  cursor: pointer;
}

.errorMessage {
  font-size: 12px;
  color: #D13A3A;
  font-weight: bold;
}

.invalidIcon {
  height: 14px;
  ;
  margin: 0 2px;
  ;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.otpInputError {
  border: 1px solid #D13A3A;
}

.desktopCarousel {
  display: none;
}

@media screen and (min-width: 600px) {
  .homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
  }
  .carouselWithHeader {
    height: 100%;;
position: relative;
  }
  
  .mobCarousel {
    display: none;
  }
  
  .desktopCarousel {
    display: block;
    filter: blur(3px);
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
  }

  .loginContainer {
    position: absolute;
    width: unset;
    top: 50vh;
    height: fit-content;
    transform: translateY(-50%);
    /* transform: translateX(-50%); */
    border-radius: 8px;
    z-index: 200;
  }
  .termsAndConditions {
    margin-top: 24px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.3);
    z-index: 10;
    top: 0;

  }
}