.loader {
  border: 4px solid #D9D9D9; 
  border-top: 4px solid #4AA9FF; 
  border-right: 4px solid #4AA9FF; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  align-self: center;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}